
import axios from 'axios';
export default {
    
    async createWorkingHours(params)  {
        return await axios.post(`working_hours/create` , params)
    },
    async createWorkingHoursList(params)  {
        return await axios.post(`working_hours/create/list` , params)
    },
    async updateWorkingHoursColumn(column , value , data)  {
        return await axios.put(`working_hours/update_list?${column}=${value}` , data)
    },
    async deleteWorkingHoursList(list)  {
        return await axios.delete(`working_hours/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportWorkingHours(column , value)  {
        return await axios.get(`working_hours/report?${column}=${value}`)
    },
    async getAllWorkingHours()  {
        return await axios.get(`working_hours/all`)
    },
    async getOneWorkingHours(working_hour_id)  {
        return await axios.get(`working_hours/all/${working_hour_id}`)
    },
    async getWorkingHoursByColumn(column , value)  {
        return await axios.get(`working_hours/filter?column=${column}&value=${value}`)
    },
    async deleteWorkingHours(working_hour_id)  {
        return await axios.delete(`working_hours/delete/${working_hour_id}`)
    },
    async updateWorkingHours(working_hour_id , params)  {
        return await axios.put(`working_hours/update/${working_hour_id}` , params)
    }
}