
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="mt-4 mb-5">{{$store.getters.language.data.working_hours.add_btn}}</h1>

			<form @submit.prevent="addWorkingHours" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="working_hours.working_hour_start" type="time" :label="$store.getters.language.data.working_hours.working_hour_start" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="working_hours.working_hour_end" type="time" :label="$store.getters.language.data.working_hours.working_hour_end" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
                    <!-- working_hour_per_day -->
                    <v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="working_hours.working_hour_per_day" type="text" :label="$store.getters.language.data.working_hours.working_hour_per_day" dense class="mx-1" filled outlined required>
                        </v-text-field>
                    </v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.working_hours.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
            <v-divider class="my-4 mt-8"></v-divider>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>

					<v-card>

						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="working_hour_id">
								<template v-slot:[`item.working_hour_id`]="{ item }">
									<div>
										<v-btn icon :to="'/working_hours-list/'+item.working_hour_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectWorkingHours(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteWorkingHoursList">{{$store.getters.language.data.working_hours.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.working_hours.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.working_hours.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteWorkingHours(selected_working_hours.working_hour_id)">
						{{$store.getters.language.data.working_hours.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/working_hours.request.js'
	export default {
		data() {
			return {
				working_hours: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_working_hours: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.working_hours.working_hour_start,
						align: 'start',
						sortable: true,
						value: 'working_hour_start',
					},
					{
						text: this.$store.getters.language.data.working_hours.working_hour_end,
						align: 'start',
						sortable: true,
						value: 'working_hour_end',
					},
                    {
                        text: this.$store.getters.language.data.working_hours.working_hour_per_day,
                        align: 'start',
                        sortable: true,
                        value: 'working_hour_per_day',

                    },
                    {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'working_hour_id',
					}
				],
			}
		},
		computed: {
		},
		mounted() {
			this.readWorkingHours();
		},
		methods: {
			addWorkingHours() {
                if (isNaN(this.working_hours.working_hour_per_day)) {
                    this.snackbar = {
                        value: true,
                        text: 'Working Hours Per Day should be a number',
                        color: 'error'
                    }
                    return
                }
				this.loading_btn = true
				requests.createWorkingHours(this.working_hours).then(r => {
					if (r.status == 200) {
						this.working_hours = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'WorkingHours Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add WorkingHours',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteWorkingHours(working_hour_id) {
				requests.deleteWorkingHours(working_hour_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.working_hour_id != working_hour_id
						})
						this.snackbar = {
							value: true,
							text: 'WorkingHours Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteWorkingHoursList() {
				let ids = this.selected_rows.map(m => m.working_hour_id)
				requests.deleteWorkingHoursList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.working_hour_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' WorkingHours Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readWorkingHours() {
				this.loading = true
				requests.getAllWorkingHours().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectWorkingHours(i) {
				this.selected_working_hours = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    